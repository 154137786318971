<template>
  <v-data-table
    dense
    :headers="headers"
    :items="items"
    item-key="name"
    class="elevation-0"
  >
    <template v-slot:item.name="{ item }">
      <div class="my-3">
        <h3>{{ item.name }}</h3>
        <div><router-link :to="`/docker/${item.id}`">View</router-link></div>
      </div>
    </template>
    <template v-slot:item.uri="{ item }">
      <copy-button :copy-string="item.uri">{{ item.uri }}</copy-button>
    </template>
    <template v-slot:item.created="{ item }">
      {{ item.created | niceDate }}
    </template>
    <template v-slot:item.actions="{ item }">
      <delete-registry-dialog
        :registry="item"
        @delete="$emit('refresh')"
        :disabled="item.tagCount > 0"
      />
    </template>
  </v-data-table>
</template>

<script>
import { niceDate } from "@/filters";
import DeleteRegistryDialog from "@/components/dialogs/DeleteRegistryDialog";
import CopyButton from "@/components/widgets/CopyButton";

export default {
  components: { CopyButton, DeleteRegistryDialog },
  props: ["items"],
  filters: { niceDate },
  data: () => ({
    headers: [
      {
        text: "Registry name",
        value: "name",
      },
      {
        text: "URI",
        value: "uri",
      },
      {
        text: "Created",
        value: "created",
      },
      {
        text: "Actions",
        value: "actions",
      },
    ],
  }),
};
</script>
