<template>
  <Dialog
    v-model="dialog"
    btn-text="Add new docker registry"
    btn-data-test="create-docker"
    btn-icon="fa-plus"
    btn-color="success"
    title="Add a new Docker registry"
  >
    <v-snackbar v-model="error" color="error">{{ errorMessage }}</v-snackbar>
    <v-card-text>
      <v-form v-model="valid" ref="form">
        <v-text-field
          label="Registry name"
          v-model="name"
          data-test="registry-name"
          :rules="[rules.required, rules.valid]"
          solo
        ></v-text-field>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <default-button
        color="success"
        icon="fa-add"
        data-test="create-docker-save"
        :disabled="!valid"
        :loading="loading"
        @click="createRegistry"
      >
        Create registry
      </default-button>
    </v-card-actions>
  </Dialog>
</template>

<script>
import { mutations } from "@/gql";
import Dialog from "@/components/dialogs/Dialog";
import DefaultButton from "@/components/widgets/DefaultButton";
const { createRegistry: mutation } = mutations;

export default {
  props: ["value"],
  components: { DefaultButton, Dialog },
  data: () => ({
    valid: null,
    name: null,
    dialog: false,
    rules: {
      required: (v) => !!v || "Registry name is required",
      valid: (v) =>
        /^[a-z-_0-9]+$/.test(v) ||
        "Registry name can only contain lowercase characters, digits, and separators",
    },
    loading: false,
    error: false,
    errorMessage: null,
  }),
  methods: {
    createRegistry() {
      this.loading = true;
      this.$apollo
        .mutate({
          mutation,
          variables: { name: this.name },
        })
        .catch((error) => {
          this.error = true;
          this.errorMessage = error.message;
          this.loading = false;
        })
        .then(() => {
          this.loading = false;
          this.dialog = false;
          this.$emit("create");
          this.$refs.form.reset();
        });
    },
  },
};
</script>
