var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-0",attrs:{"dense":"","headers":_vm.headers,"items":_vm.items,"item-key":"name"},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"my-3"},[_c('h3',[_vm._v(_vm._s(item.name))]),_c('div',[_c('router-link',{attrs:{"to":("/docker/" + (item.id))}},[_vm._v("View")])],1)])]}},{key:"item.uri",fn:function(ref){
var item = ref.item;
return [_c('copy-button',{attrs:{"copy-string":item.uri}},[_vm._v(_vm._s(item.uri))])]}},{key:"item.created",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("niceDate")(item.created))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('delete-registry-dialog',{attrs:{"registry":item,"disabled":item.tagCount > 0},on:{"delete":function($event){return _vm.$emit('refresh')}}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }