<template>
  <Dialog v-model="dialog" title="Really delete registry?">
    <v-snackbar v-model="error" color="error">{{ errorMessage }}</v-snackbar>
    <template v-slot:activator="{ on }">
      <default-button
        :on="on"
        color="error"
        icon="fa-trash-alt"
        :disabled="disabled"
        >Delete Docker Registry</default-button
      >
    </template>
    <v-card-text>
      You are about to delete the docker registry {{ registry.name }}. This
      action cannot be undone. Are you sure you want to proceed?
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn text color="error" :loading="loading" @click="deleteRegistry"
        >Delete registry</v-btn
      >
    </v-card-actions>
  </Dialog>
</template>

<script>
import Dialog from "@/components/dialogs/Dialog";
import DefaultButton from "@/components/widgets/DefaultButton";

import { mutations } from "@/gql";

const { deleteRegistry: mutation } = mutations;

export default {
  props: ["registry", "disabled"],
  components: { DefaultButton, Dialog },
  data: () => ({
    error: false,
    errorMessage: null,
    loading: false,
    dialog: false,
  }),
  methods: {
    deleteRegistry() {
      this.loading = true;
      this.$apollo
        .mutate({
          mutation,
          variables: {
            pk: this.registry.id,
          },
        })
        .catch((error) => {
          this.error = true;
          this.errorMessage = error.message;
          this.loading = false;
        })
        .then((result) => {
          if (result) {
            this.loading = false;
            this.$emit("delete");
            this.dialog = false;
          }
        });
    },
  },
};
</script>
