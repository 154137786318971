<template>
  <v-row class="ma-4">
    <page-header />
    <v-col cols="12">
      <v-card tile>
        <table-header title="Registries">
          <create-registry-dialog
            @create="$apollo.queries.registries.refetch()"
          />
          <default-button
            @click="$apollo.queries.registries.refetch()"
            icon="fa-sync"
            >Refresh</default-button
          >
        </table-header>
        <v-card-text>
          <docker-registries
            @refresh="$apollo.queries.registries.refetch()"
            :items="registries"
          />
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import CreateRegistryDialog from "@/components/dialogs/CreateRegistryDialog";
import PageHeader from "@/components/widgets/PageHeader";
import TableHeader from "@/components/widgets/TableHeader";
import DefaultButton from "@/components/widgets/DefaultButton";
import { queries } from "@/gql";
import DockerRegistries from "@/components/tables/DockerRegistries";

const { registries } = queries;

export default {
  components: {
    DockerRegistries,
    DefaultButton,
    TableHeader,
    PageHeader,
    CreateRegistryDialog,
  },
  apollo: { registries },
};
</script>
